<template>
  <div>

    <div class="main-page-content">
      <el-row class="table-header">
        <el-col :span="20">
          <el-form :inline="true" size="mini">
            <el-form-item v-if="userPermissions.indexOf('business_manage_create') !== -1">
              <el-tooltip effect="dark" content="添加" placement="top-start">
                <el-button type="primary" size="mini" icon="el-icon-plus" @click="addBtn"></el-button>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="">
              <el-date-picker
                  v-model="searchCondition.ym"
                  type="month"
                  value-format="yyyy-MM"
                  format="yyyy年MM月"
                  placeholder="选择录入月份" style="width: 160px" @change="changeMonth">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="">
              <el-date-picker
                  v-model="searchCondition.release_month"
                  type="month"
                  value-format="yyyy-MM"
                  format="yyyy年MM月"
                  placeholder="选择发布月份" style="width: 160px">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="">
              <el-select v-model="searchCondition.done_status" placeholder="完成状态" clearable>
                <el-option :label="`完成`" :value="`Y`"></el-option>
                <el-option :label="`未完成`" :value="`N`"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <artist-search-custom :type="``" @handleSelect="selectArtist" style="width: 100%"/>
            </el-form-item>
            <el-form-item>

            </el-form-item>
            <el-form-item>
              <!--    消息提醒-->
              <DelayNotify :auto="false" :visible.sync="dialogNotifyVisible" @execStep="execStep"
                           v-if="userPermissions.indexOf('business_manage_delay_notify')>-1">
                <el-link slot="content" type="primary" @click="dialogNotifyVisible=true">逾期<i class="el-icon-time"></i></el-link>
              </DelayNotify>
            </el-form-item>
          </el-form>
        </el-col>

        <el-col :span="4" style="text-align: right">
          <el-link class="default-log-link" type="primary" @click="showLog"
                   v-if="userPermissions.indexOf('business_manage_log') !== -1">
            日志
            <i class="el-icon-document"></i></el-link>

          <export-link ref="refExportLink" :can-export="userPermissions.indexOf('business_manage_export') !== -1"
                       @export="exportData"/>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <business-table ref="refBusinessTable" :search-condition="searchCondition" @execStep="execStep"
                          @handleCmd="handleCmd" @currentSearch="setCurrentSearch"/>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <Tips :data="tipsList"/>
        </el-col>
      </el-row>
    </div>

    <!--    添加/编辑 -->
    <CreateEdit :visible.sync="dialogVisible" :edit-obj="currentRow" @refreshData="refreshData"/>

    <!--    执行进度-->
    <RateProgress :visible.sync="dialogExecVisible" :edit-obj="currentRow" @refreshData="refreshData"/>

    <!--    日志-->
    <BusinessPlanLog :visible="logSetting.visible" @close="closeLog" :info-id="logForm.info_id||null"
                     v-if="userPermissions.indexOf('business_manage_log') !== -1"/>
  </div>
</template>

<script>
import ApeTable from "@/components/ApeTable";
import ApeDrawer from "@/components/ApeDrawer";
import ArtistSearchCustom from "@/components/artist/ArtistSearchCustom";
import ExportLink from "@/components/export/ExportLink";
import { mapGetters } from "vuex";
import BusinessTable from './BusinessTable'
import CreateEdit from './CreateEdit'
import RateProgress from './RateProgress'
import BusinessPlanLog from './BusinessPlanLog'

export default {
  name: "BusinessList",
  components: {
    ApeTable,
    ApeDrawer,
    ArtistSearchCustom,
    ExportLink,
    BusinessPlanLog,
    BusinessTable,
    CreateEdit,
    RateProgress
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  props: {},
  data() {
    return {
      searchCondition: {
        ym: '',
        release_month: null,
        year: null,
        month: null,
        nickname: null,
        artist_id: null,
        group_id: null,
        done_status: null,
      },
      artists: [],
      fileTitle: '',
      logSetting: {
        visible: false,
      },
      dialogVisible: false,
      dialogExecVisible: false,
      dialogNotifyVisible: false,
      canEdit: true,
      currentRow: {},
      logForm: {},
      exportForm: {},
      tipsList: [
        '右键菜单：编辑、日志',
        '录入条件：录入权限和数据权限',
        '排序规则：默认发布时间从小到大，并且已完成发布的排在最后',
        '注意：修改发布时间，不会同步档期时间，仅设置状态为“延期”才会修改档期时间',
      ],
      currentGroups: []
    }
  },
  methods: {
    handleCmd(cmd, currentRow) {
      this.currentRow = currentRow
      // this.$notify.info(`cmd:${ cmd }`)
      switch (cmd) {
        case 'copy':
          this.copy()
          break
        case 'edit':
          this.editRow(currentRow)
          break;
        case 'del':
          this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.delRow(currentRow)

          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
          });
          break;
        case 'log':
          // this.$message.warning('查看日志')
          // this.$emit('showLog', this.currentRow)
          this.showRowLog(currentRow)
          break;
      }
    },
    async copy() {
      if (this.currentRow.id) {
        let postData = { ...this.currentRow }
        delete postData.id
        // console.log('post data', postData)
        await this.$api.saveBusinessPlan(postData)
        this.refreshData()
      }

      // this.close()
    },
    addBtn() {
      this.currentRow = {}
      this.dialogVisible = true
    },
    async delRow(currentRow) {
      await this.$api.delBusinessPlan(currentRow.id)
      this.refreshData()
    },
    editRow() {
      this.dialogVisible = true
    },
    defaultTime() {
      const end = new Date();
      //年份选择：默认当月
      this.searchCondition.year = end.getFullYear()
      this.searchCondition.month = end.getMonth() + 1
      this.searchCondition.ym = end.getFullYear() + '-' + (end.getMonth() + 1)
    },
    changeMonth() {
      if (this.searchCondition.ym) {
        let ymArr = this.searchCondition.ym.split('-')
        this.searchCondition.year = parseInt(ymArr[0])
        this.searchCondition.month = parseInt(ymArr[1])
      } else {
        this.searchCondition.year = null
        this.searchCondition.month = null
      }

      this.showRecords()
    },
    async selectArtist(selectInfo) {
      // console.log('si', selectInfo)
      this.searchCondition = { ...this.searchCondition, ...selectInfo }
      // await this.calcArtistPlatforms()
      // 筛选
      // this.showRecords()
    },

    async chooseArtist(item) {
      if (item && item.group_id) {
        this.searchCondition.group_id = item.group_id
        this.searchCondition.group_name = item.group_name
        this.$refs['refDeptCascader'].initVal(item.group_id)
        await this.getGroupArtists('search')
        this.searchCondition.artist_id = item.id
        this.searchCondition.nickname = item.nickname
        await this.changeArtist()
      } else {
        this.searchCondition.artist_id = null
        this.$refs['refDeptCascader'].initVal(null)
      }
    },
    async getGroupArtists(type) {
      this.searchCondition.artist_id = null
      this.searchCondition.nickname = null
      let groupId = this.searchCondition.group_id
      let { list } = await this.$api.getGroupSignArtists(groupId)
      this.artists = list
      if (type !== 'search' && list.length > 0) {
        this.searchCondition.artist_id = list[0].id
        await this.changeArtist()
      }
    },
    async selectDept(val) {
      let group_id = null;
      this.searchCondition.depth = null
      if (val && val.length > 1) {
        this.searchCondition.depth = val.length
        group_id = val[val.length - 1];
        this.searchCondition.group_id = group_id
        await this.getGroupArtists()
      }
    },
    async currentGroup(group) {
      this.searchCondition.group_id = group.dpt_id
      this.currentGroups = [group.dpt_id]
      await this.getGroupArtists()
    },
    async selectGroup(val) {
      this.searchCondition.group_id = val
      await this.getGroupArtists()
      // this.showRecords()
    },
    async changeArtist() {
      // 检索红人昵称
      let artist = this.artists.find((value) => value.id === this.searchCondition.artist_id)
      this.searchCondition.nickname = artist ? artist.nickname : ''
      // this.searchCondition.platformMap = artist.platforms
      this.showRecords()
    },
    showRecords() {
      // console.log('===============')
      // console.log(this.searchCondition)
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }
      if (this.searchCondition.ym) {
        let ym = this.searchCondition.ym
        let ymArr = ym.split('-')
        let day = new Date(parseInt(ymArr[0]), parseInt(ymArr[1]), 0);
        let daycount = day.getDate();//最后一天

        // this.searchCondition.time_value = [ym + '-01', ym + '-' + daycount]
        condition.start_time = ym + '-01'
        condition.end_time = ym + '-' + daycount
      }

      return condition
    },

    //导出表格
    async exportData() {
      try {
        let response = await this.$api.exportBusinessPlans(this.exportForm)
        let name = `商务管理`
        if (this.searchCondition.ym) {
          name += `-${ this.searchCondition.ym }录入`
        }
        if (this.searchCondition.release_month) {
          name += `-${ this.searchCondition.release_month }发布`
        }
        if (this.searchCondition.done_status) {
          name += `-${ this.searchCondition.done_status === 'Y' ? '完成' : '未完成' }`
        }
        if (this.searchCondition.artist_id) {
          name += `-${ this.searchCondition.nickname }`
        }

        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }
    },
    //计算权限
    calcPermission() {
      //录入权限
      let hasPermission = this.userPermissions.indexOf('schedule_create') > -1
      //范围权限
      let rangePermission = false

      let currentGroupId = this.searchCondition.group_id
      //数据范围
      //有全公司 数据权限
      if (this.userPermissions.indexOf('schedule_data_company') > -1) {
        rangePermission = true

      } else if (this.userPermissions.indexOf('schedule_data_dpt') > -1) {
        // 当前选中的组在本部及其组，则有权限修改
        let dptRange = this.userDptInfo.dpt_range
        rangePermission = dptRange ? dptRange.indexOf(currentGroupId) > -1 : false

      } else if (this.userPermissions.indexOf('schedule_data_group') > -1) {
        // 当前选中的组在个人归属的组（一个人可在多组），则有权限修改
        let dpt_ids = this.userDptInfo.my_dpt_range ? this.userDptInfo.my_dpt_range : []
        rangePermission = dpt_ids ? dpt_ids.indexOf(currentGroupId) > -1 : false

      } else {
        rangePermission = false
      }

      // console.log(`组：${ this.searchCondition.group_id }, hasPermission:${ hasPermission }`)
      return hasPermission && rangePermission
    },
    refreshData() {
      this.$refs['refBusinessTable'].initList()
    },
    execStep(row) {
      this.currentRow = row
      this.dialogExecVisible = true
    },
    showLog() {
      this.logForm = {}
      this.logSetting.visible = true
    },
    showRowLog(row) {
      this.logForm.info_id = row.id
      this.logSetting.visible = true
    },
    closeLog() {
      this.logSetting.visible = false
    },
    setCurrentSearch(val) {
      this.exportForm = val
    },

  },
  created() {
    this.defaultTime()
  },
  mounted() {
    this.refreshData()
  }
}
</script>

<style scoped>

</style>
